const initialState = {
  loading: false,
  balanceOf: false,
  maxMintsPerPS: 99,
  isApprovedForAll: null,
  imageURI: [],
  error: false,
  errorMsg: "",
};

const dataSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATASUB_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_SUCCESS":
      return {
        ...state,
        loading: false,
        balanceOf: action.payload.balanceOf,
        isApprovedForAll: action.payload.isApprovedForAll,
        ownerAddress: action.payload.ownerAddress,
        imageURI: action.payload.imageURI,

        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataSubReducer;
