const initialState = {
  loading: false,
  lightPhase: 0,
  premiumPhase: 0,
  elitePhase: 0,
  cost: 0,
  error: false,
  errorMsg: "",
};

const dataPhaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATAPHASE_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATAPHASE_SUCCESS":
      return {
        ...state,
        loading: false,
        lightPhase: action.payload.lightPhase,
        premiumPhase: action.payload.premiumPhase,
        elitePhase: action.payload.elitePhase,

        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATAPHASE_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataPhaseReducer;
